import React, { useEffect, useMemo, useRef } from "react";
import { useLocation } from "@reach/router";
import { useIntl } from "react-intl";
import { Icon, Logo, SEOHead } from "../components";
import { Fabrico } from "../sdk/sdk.js";
import { useSiteMetadata, useVerifyTokenAndGetConfig } from "../hooks";

export default function EditorPage() {
  const intl = useIntl();
  const { colors } = useSiteMetadata();

  const location = useLocation();
  const editor = useMemo(() => new Fabrico(), []);
  const editorRef = useRef(null);

  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const { data, isError, isLoading, error } = useVerifyTokenAndGetConfig(token);

  useEffect(() => {
    if (!data) {
      return;
    }

    async function setup() {
      try {
        const { configuration } = data;
        const token = new URLSearchParams(window.location.search).get("token");
        await editor.initialize(editorRef.current, token, configuration);
        await editor.selectProduct(configuration.defaultProduct);
      } catch (e) {
        console.error(e);
      }
    }

    setup();
  }, [editor, editorRef, data]);

  let content = <div ref={editorRef} className="overflow-hidden"></div>;

  if (isLoading) {
    content = (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 flex flex-col items-center justify-center">
        <Icon
          name="Spinner"
          className="animate-spin -ml-1 mr-3"
          size="55"
          color={colors.primary}
        />
        <h2 className="mt-2 text-center text-xl font-semibold">
          {intl.formatMessage({ id: "page.editor.loading" })}
        </h2>
      </div>
    );
  }

  if (isError) {
    content = (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 flex flex-col items-center justify-center">
        <Logo large />
        <h2
          className="mt-2 text-center text-xl font-semibold"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: `page.error.${error || "unknown_error"}`,
            }),
          }}
        />
      </div>
    );
  }

  return (
    <>
      <SEOHead title={intl.formatMessage({ id: "page.editor.title" })} />
      {content}
    </>
  );
}
